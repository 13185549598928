import type { SiteIdValues } from './siteId';

export const hostsProductionToSiteId: Record<string, SiteIdValues> = {
  // sonara.ai will match : sonara.ai, www.sonara.ai, stg.sonara.ai
  'sonara.ai': 'sonara',

  // flexjobs.com will match : flexjobs.com, www.flexjobs.com, stg.flexjobs.com
  'flexjobs.com': 'flexjobs',
};

export const hostsTestingToSiteId: Record<string, SiteIdValues> = {
  // sonara-sb.com would match qa.sonara-sb.com, reg.sonara-sb.com but we still need more for getDeployEnvFromHostname
  'qa.sonara-sb.com': 'sonara',
  'reg.sonara-sb.com': 'sonara',

  'qa.fj-staging.com': 'flexjobs',
  'reg.fj-staging.com': 'flexjobs',
};

export const hostsToSiteId: Record<string, SiteIdValues> = {
  'fj-staging.com': 'flexjobs',
  'ilcvperfetto.it': 'myperfectresumeit',
  'interviewme.pl': 'interviewme',
  'livecareer.co.uk': 'livecareeruk',
  'livecareer.com': 'livecareer',
  'livecareer.com.br': 'livecareerbr',
  'livecareer.es': 'livecareeres',
  'livecareer.fr': 'livecareerfr',
  'livecareer.it': 'livecareerit',
  'livecareer.mx': 'livecareermx',
  'livecareer.nl': 'livecareernl',
  'livecareer.pl': 'livecareerpl',
  'livecareer.pt': 'livecareerpt',
  'meinperfekterlebenslauf.de': 'myperfectresumede',
  'meucurriculoperfeito.com.br': 'myperfectresumebr',
  'micvideal.es': 'myperfectresumees',
  'micvideal.mx': 'myperfectresumemx',
  'moncvparfait.fr': 'moncvparfait',
  'myperfectcv.co.uk': 'myperfectresumeuk',
  'myperfectresume.com': 'myperfectresume',
  'resume-now.com': 'resumenow',
  'resumelab.com': 'resumelab',
  'sonara-sb.com': 'sonara', // TODO XXX is it final
  'zety.com': 'zetycom',
  'zety.de': 'zetyde',
  'zety.dk': 'zetydk',
  'zety.es': 'zetyes',
  'zety.fr': 'zetyfr',
  'zety.it': 'zetyit',
  'zety.se': 'zetyse',
};

export const hostTestingPrefix = 'app2.testing.modularfeatures.';

export const hostDefaultTesting = `${hostTestingPrefix}workz.pl`;

export const hostsTestingModularFeatures = Object.keys(hostsToSiteId).map((item) => `${hostTestingPrefix}${item}`);

export const hostsLocalToSiteId: Record<string, SiteIdValues> = {
  ...Object.fromEntries(Object.entries(hostsToSiteId).map(([key, value]) => [`local.${key}`, value])),

  // additional based on removed AVAILABLE_LOCAL_HOSTS_FT
  'local.livecareer.de': 'livecareerde',
  'local.zety.br': 'zetybr',
  'local-dk.livecareer.com': 'livecareerdk',
  'local-se.livecareer.com': 'livecareerse',
};

export const hostsLocal = Object.keys(hostsLocalToSiteId);
